import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import virtual_lesson from "../../assets/images/blog-image/virtual_lesson.jpg";
import vr from "../../assets/images/blog-image/vr.jpg";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 23,
    title: "React Apollo:",
    desc: "Come Gestire lo State Management con GraphQL nel Tuo Progetto",
    img: "/blog-image/react-apollo.png",
    page: "blog/react-apollo",
    data: "02 Ott 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "React Apollo è una libreria potente che permette di incorporare GraphQL nei tuoi progetti React o React Native.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        React Apollo è una libreria potente che
                                        permette di incorporare GraphQL nei tuoi
                                        progetti React o React Native. In questo
                                        articolo, ti guideremo attraverso il
                                        processo di utilizzo di React Apollo per
                                        gestire lo state management con GraphQL,
                                        illustrando vantaggi, tecniche e best
                                        practices che potrai sfruttare per
                                        migliorare la tua applicazione.
                                    </p>

                                    <h3>Cos'è React Apollo? </h3>

                                    <p>
                                        Apollo è una suite di strumenti che
                                        facilita la gestione di data, cache, e
                                        UI state con GraphQL nelle applicazioni
                                        React. Grazie a React Apollo, potrai
                                        beneficiare di un insieme di
                                        funzionalità che renderanno la
                                        manipolazione di dati più semplice e il
                                        tuo codice più pulito ed efficiente.
                                    </p>

                                    <h5>
                                        Vantaggi dell'Utilizzo di React Apollo
                                        con GraphQL:
                                    </h5>
                                    <ol>
                                        <li>
                                            <b>Ottimizzazione delle Query</b>
                                            :Con Apollo, potrai ottimizzare le
                                            tue query, richiedendo solo i dati
                                            necessari, alleggerendo così il
                                            carico sul server e migliorando le
                                            prestazioni della tua applicazione.
                                        </li>
                                        <li>
                                            <b>Gestione della Cache</b>:Apollo
                                            Client gestisce automaticamente la
                                            cache, semplificando la gestione dei
                                            dati locali e offrendoti tempi di
                                            caricamento ridotti.
                                        </li>
                                        <li>
                                            <b>Flessibilità e Compatibilità</b>
                                            :React Apollo è compatibile con
                                            qualsiasi build setup e può essere
                                            integrato facilmente con altre
                                            librerie di state management come
                                            Redux.
                                        </li>
                                        <li>
                                            <b>
                                                Esperienza di Sviluppo
                                                Migliorata
                                            </b>
                                            :Strumenti come Apollo Studio
                                            offrono un'esperienza di sviluppo
                                            avanzata, con introspezione delle
                                            API, analytics, e altro.
                                        </li>
                                    </ol>
                                    <h3>
                                        Come Integrare React Apollo nel Tuo
                                        Progetto:
                                    </h3>
                                    <ol>
                                        <li>
                                            <h5>Installazione:</h5>
                                            <p>
                                                Per cominciare, installa Apollo
                                                Client nel tuo progetto con npm
                                                o yarn.
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`npm install @apollo/client graphql`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                            <p>o</p>
                                            <MyCoolCodeBlock
                                                code={`yarn add @apollo/client graphql`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <br />
                                        {isMobile ? (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? <br />
                                                        <span
                                                            style={{
                                                                fontSize: 20,
                                                            }}
                                                        >
                                                            Clicca qui
                                                        </span>
                                                    </Link>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? Clicca
                                                        qui
                                                    </Link>
                                                </div>
                                            </>
                                        )}
                                        <br />
                                        <li>
                                            <h5>Configurazione</h5>
                                            <p>
                                                Configura Apollo Client
                                                collegandolo al tuo endpoint
                                                GraphQL. Ecco un esempio di
                                                base:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`
import { ApolloClient, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
    uri: 'http://your-graphql-endpoint.com',
    cache: new InMemoryCache()
});
`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <br />

                                        <li>
                                            <h5>Creazione delle Query</h5>
                                            <p>
                                                Definisci le tue query GraphQL.
                                                Ad esempio:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`
import { gql } from '@apollo/client';

export const GET_USERS = gql'
    query GetUsers {
        users {
            id
            name
            email
        }
    }
';
`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li>
                                            <h5>Utilizzo delle Query</h5>
                                            <p>
                                                Utilizza il client Apollo per
                                                effettuare query nei tuoi
                                                componenti React:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`
import { useQuery } from '@apollo/client';
import { GET_USERS } from './queries';

function UsersComponent() {
    const { loading, error, data } = useQuery(GET_USERS);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return data.users.map(({ id, name }) => (
        <div key={id}>
            <p>{name}</p>
        </div>
    ));
}
`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                    </ol>
                                    <p>
                                        Integrare React Apollo e GraphQL rende
                                        la gestione dello state nelle tue
                                        applicazioni React e React Native un
                                        gioco da ragazzi, offrendoti anche
                                        strumenti che ottimizzano le prestazioni
                                        e migliorano l'esperienza di sviluppo.
                                        Con GraphQL e React Apollo, creare
                                        applicazioni robuste e scalabili è più
                                        facile che mai, e se hai bisogno di una
                                        consulenza personalizzata puoi sempre
                                        rivolgerti a noi. Nel frattempo… Buon
                                        coding!
                                    </p>

                                    <br />
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>

                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};
export default BlogDetails;
